var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "comenm" },
        [
          _c(
            "van-tabs",
            {
              staticStyle: { width: "70%", "padding-top": "10px" },
              attrs: { color: "#1890FF", "title-active-color": "#1890FF" },
              on: { change: _vm.toggles },
              model: {
                value: _vm.localOrLine,
                callback: function ($$v) {
                  _vm.localOrLine = $$v
                },
                expression: "localOrLine",
              },
            },
            [
              _c("van-tab", { attrs: { title: "公开文书", name: "0" } }),
              _c("van-tab", { attrs: { title: "本地文书", name: "1" } }),
            ],
            1
          ),
          _c(
            "van-collapse",
            {
              staticClass: "collapseStyle",
              model: {
                value: _vm.activeNames,
                callback: function ($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames",
              },
            },
            [
              _c(
                "van-collapse-item",
                {
                  ref: "checkbox",
                  staticStyle: { "font-size": "18px" },
                  attrs: { title: _vm.title, name: "1" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { color: "black", "font-size": "18px" },
                      on: {
                        click: function ($event) {
                          return _vm.collapseItemHanlder("2", "全部")
                        },
                      },
                    },
                    [_vm._v("全部")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { color: "black", "font-size": "18px" },
                      on: {
                        click: function ($event) {
                          return _vm.collapseItemHanlder("0", "未归档")
                        },
                      },
                    },
                    [_vm._v("未归档")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { color: "black", "font-size": "18px" },
                      on: {
                        click: function ($event) {
                          return _vm.collapseItemHanlder("1", "已归档")
                        },
                      },
                    },
                    [_vm._v("已归档")]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "liteBox",
          class: _vm.show ? "listheaight" : "listheaighted",
          on: { scroll: _vm.handleScroll },
        },
        [
          _vm._l(_vm.dataList, function (item, index) {
            return _c(
              "van-swipe-cell",
              {
                key: index,
                staticClass: "liStyle",
                scopedSlots: _vm._u(
                  [
                    _vm.localOrLine == 1
                      ? {
                          key: "right",
                          fn: function () {
                            return [
                              _c("van-button", {
                                staticStyle: {
                                  "background-color": "red",
                                  color: "white",
                                },
                                attrs: { text: "删除" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delWrit(item)
                                  },
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                  ],
                  null,
                  true
                ),
              },
              [
                _c(
                  "van-cell",
                  {
                    attrs: { border: false },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.toIndependent(item)
                      },
                    },
                  },
                  [
                    _c("div", [
                      item.isArchive
                        ? _c("span", { staticClass: "borderStyle" }, [
                            _vm._v("归档"),
                          ])
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          staticClass: "borderStyle",
                          staticStyle: {
                            border: "1px solid #1890ff",
                            color: "#1890ff",
                          },
                        },
                        [_vm._v(_vm._s(item?.caseWrit))]
                      ),
                      _c("span", { staticClass: "titleStyle" }, [
                        _vm._v(_vm._s(item.caseName)),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          color: "#999999",
                          "font-size": "0.39rem",
                        },
                      },
                      [
                        _c("span", [_vm._v(" " + _vm._s(item.caseId) + "/")]),
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm._f("reverse")(item.judgeDate))
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ],
              1
            )
          }),
          _vm.noMore
            ? _c(
                "div",
                { staticStyle: { width: "100%", "text-align": "center" } },
                [_vm._v("没有更多数据")]
              )
            : _vm._e(),
        ],
        2
      ),
      _c("div", { staticClass: "gonna", on: { click: _vm.takePhotoPop } }, [
        _c("i", { staticClass: "iconfont icon-a-shuangjiantoushang" }),
      ]),
      _c(
        "van-popup",
        {
          style: {
            height: "20%",
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.2)",
          },
          attrs: { round: "", position: "bottom", overlay: false },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "18px",
                height: "8px",
                margin: "auto",
                "margin-top": "19px",
                color: "#1890ff",
              },
            },
            [
              _c("i", {
                staticClass: "iconfont icon-a-shuangjiantouxia",
                on: { click: _vm.takePhotoPop },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-evenly",
                "margin-top": "10%",
              },
            },
            [
              _c("i", {
                staticClass: "iconfont icon-a-zu452",
                staticStyle: {
                  "font-size": "1.5rem",
                  "margin-left": "1rem",
                  color: "#666666",
                },
                on: { click: _vm.relap },
              }),
              _c("i", {
                staticClass: "iconfont icon-wenjian6",
                staticStyle: { "font-size": "1.5rem", color: "#666666" },
                on: { click: _vm.openFile },
              }),
            ]
          ),
        ]
      ),
      _c(
        "van-popup",
        {
          style: { height: "70%" },
          attrs: { round: "", position: "bottom" },
          model: {
            value: _vm.showlawyer,
            callback: function ($$v) {
              _vm.showlawyer = $$v
            },
            expression: "showlawyer",
          },
        },
        [
          _c("iframe", {
            attrs: {
              src: "/lawyerAuth?showLay=0",
              frameborder: "0",
              width: "100%",
              height: "100%",
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }