var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "fb-header",
        {
          staticStyle: { "text-align": "left" },
          attrs: { slot: "header", title: "提取文字" },
          slot: "header",
        },
        [
          _c("van-icon", {
            staticClass: "arrow",
            attrs: { slot: "left", name: "arrow-left" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.back.apply(null, arguments)
              },
            },
            slot: "left",
          }),
        ],
        1
      ),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.tempTexted,
            expression: "tempTexted",
          },
        ],
        staticClass: "textStyle",
        attrs: { id: "txt", cols: "30", rows: "10" },
        domProps: { value: _vm.tempTexted },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.tempTexted = $event.target.value
            },
            _vm.inputTempText,
          ],
        },
      }),
      _c(
        "div",
        { staticClass: "bottom_box" },
        [
          _c(
            "van-button",
            {
              staticStyle: { width: "75vw", "border-radius": "0.15rem" },
              attrs: { type: "info" },
              on: { click: _vm.nextStep },
            },
            [_vm._v("下一步")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }