var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    attrs: {
      src: _vm.url,
      frameborder: "0",
      width: "100%",
      height: "100%",
      seamless: "",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }