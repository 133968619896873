<template>
  <div class="container">
    <fb-header slot="header" title="提取文字" style="text-align: left">
      <van-icon name="arrow-left" class="arrow" slot="left" @click.stop="back" />
    </fb-header>
    <textarea id="txt" cols="30" rows="10" class="textStyle" v-model="tempTexted" @input="inputTempText"></textarea>
    <div class="bottom_box">
      <van-button type="info" @click="nextStep" style="width: 75vw; border-radius: 0.15rem">下一步</van-button>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { Notify } from 'vant'
export default {
  components: {},
  data() {
    return {
      tempTexted: '',
    }
  },
  computed: {
    ...mapState('user', ['userInfo', 'userinfoAll']),
    ...mapState('dingjuan', ['tempText']),
  },
  methods: {
    ...mapMutations('dingjuan', ['SET_TEMPTEXT']),
    back() {
      this.$router.push({
        path: '/dingjuan',
      })
    },
    inputTempText(e) {
      this.tempTexted = e.target.value
      this.SET_TEMPTEXT(this.tempTexted)
    },
    nextStep() {
      if (!this.tempTexted) {
        Notify({ type: 'danger', message: '请正确填写信息' })
        return
      }
      try {
        const u = navigator.userAgent
        let changeUSB = ''
        if (u === 'fb-Android-Webview') {
          changeUSB = this.userinfoAll
          this.SET_TEMPTEXT(this.tempTexted)
          console.log('fb-Android-Webview', u, changeUSB)
          this.$router.push({
            path: '/dingjuan/dangshiren',
            query: {
              changeUSB: JSON.stringify(changeUSB),
              comeWel: 'imgText',
            },
          })
        } else if (u === 'fb-iOS-Webview') {
          changeUSB = this.userinfoAll
          this.SET_TEMPTEXT(this.tempTexted)
          console.log('fb-iOS-Webview', u, changeUSB)
          this.$router.push({
            path: '/dingjuan/dangshiren',
            query: {
              changeUSB: JSON.stringify(changeUSB),
              comeWel: 'imgText',
            },
          })
        } else if (u === 'fb-flutter-Webview') {
          changeUSB = this.userinfoAll
          this.SET_TEMPTEXT(this.tempTexted)
          console.log('fb-flutter-Webview', u, changeUSB)
          this.$router.push({
            path: '/dingjuan/dangshiren',
            query: {
              changeUSB: JSON.stringify(changeUSB),
              comeWel: 'imgText',
            },
          })
        } else {
          changeUSB = JSON.parse(window.sessionStorage.userInfo)
          this.SET_TEMPTEXT(this.tempTexted)
          console.log('pc环境', u, changeUSB)
          this.$router.push({
            path: '/dingjuan/dangshiren',
            query: {
              changeUSB: JSON.stringify(changeUSB),
              comeWel: 'imgText',
            },
          })
        }
      } catch (err) {
        this.$notify(JSON.stringify(err))
      }
    },
  },
  created() {},
  mounted() {
    this.tempTexted = this.tempText
  },
}
</script>

<style scoped>
.container {
  padding: 20rpx 20rpx 120rpx;
  box-sizing: border-box;
  position: relative;
}

.bottom_box {
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  width: 100%;
  height: 100rpx;
  padding: 0 80rpx;
  text-align: center;
  background: #fff;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  font-size: 15px;
  padding-left: 0.2rem;
}

.textStyle {
  font-size: 16px;
  width: 95vw;
  margin: 0.4rem 0.2rem;
  padding: 0.1rem 0.2rem;
  height: 80vh;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>
