<template>
  <iframe :src="url" frameborder="0" width="100%" height="100%" seamless></iframe>
</template>
<script>
export default {
  data() {
    return {
      url: '',
    }
  },
  components: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {
    this.url = `https://tool.farbun.com/office/moblieDownload?independentDocumentId=${this.$route.query.independentDocumentId}`
    // this.url = `https://test.farbun.com/office/moblieDownload?independentDocumentId=${this.$route.query.independentDocumentId}`
  },
}
</script>

<style scoped></style>
