<template>
  <div class="container" @click.stop="selectCompanyFalse">
    <fb-header slot="header" title="确认信息" style="text-align: left">
      <van-icon name="arrow-left" class="arrow" slot="left" @click.stop="back" />
    </fb-header>
    <van-loading type="spinner" size="80px" v-if="showLoading" vertical style="margin-top: 100px" text-size="20px">加载中...</van-loading>
    <van-form :model="form" ref="uForm" label-width="2rem" style="border-bottom: 1px solid rgba(153, 153, 153, 1); height: 85vh; overflow-y: scroll; padding-left: 0.2rem" v-else>
      <van-field name="switch" v-show="anjianleixingShow" label="案件类型" :center="true" :required="true">
        <template #input>
          <van-dropdown-menu title="案件类型" style="width: 90%" active-color="#1890FF" overlay>
            <van-dropdown-item v-model="form.case_type" :options="rangeCase_type" @change="changeCase_type" :title="form.case_type || '请选择'"></van-dropdown-item>
          </van-dropdown-menu>
        </template>
      </van-field>
      <van-field label="审理程序" v-show="shenlichengxuShow" :border="true" :required="true" :center="true">
        <template #input>
          <van-dropdown-menu title="审理程序" style="width: 90%" active-color="#1890FF" overlay>
            <van-dropdown-item v-model="form.proceeding" :options="rangeProceeding" @change="changeProceeding" :title="form.proceeding || '请选择'"></van-dropdown-item>
          </van-dropdown-menu>
        </template>
      </van-field>
      <van-field label="文书性质" v-show="wenshuxingzhiShow" :required="true" :center="true">
        <template #input>
          <van-dropdown-menu title="文书性质" style="width: 90%" active-color="#1890FF" overlay>
            <van-dropdown-item v-model="form.case_writ" :options="rangeCase_writ" @change="changeCase_writ" :title="form.case_writ || '请选择'"></van-dropdown-item>
          </van-dropdown-menu>
        </template>
      </van-field>
      <div style="position: relative" v-show="anyouShow">
        <van-field v-model="inputCauseName" name="案由" label="案由" :required="true" placeholder="请输入案由" :center="true" @onBlur="blurName()" @input="inputName($event)" />
        <ul class="popupStyle" v-if="companyList.length">
          <li v-for="item in companyList" :key="item.text" class="liStyle" @click.stop="selectCompany(item)">
            {{ item.text }}
          </li>
        </ul>
        <div style="width: 110%; height: 95vh; background-color: rgba(0, 0, 0, 0.3); position: fixed; z-index: 1; top: 1.2rem; left: 0" v-show="companyList.length"></div>
        <div class="sanjao" v-if="companyList.length"></div>
      </div>
      <div v-if="form.caseReasons.length" class="law_tag" v-show="anyouShow">
        <div style="padding: 0.1rem" v-for="(item, index) in form.caseReasons" :key="index">
          <van-tag mode="plain" type="primary" closeable @close="tagClick(index)">
            {{ item.causeName }}
          </van-tag>
        </div>
      </div>
      <van-field :label="value.litigantIdentType" v-model="value.name" v-for="(value, index) in precedentsData.litigantList" :key="index" @input="changeProcedure(index, $event)" :required="true">
        <div style="width: 97%; height: 1px; background-color: #d8d8d8"></div>
      </van-field>
      <van-field label="裁判日期" :value="caipan" readonly clickable v-show="caipanriqiShow" name="calendar" placeholder="请选择裁判日期" @click="caipanriqi = true" :center="true" :required="true" />
      <van-calendar v-model="caipanriqi" @confirm="getCaipanDate" color="#1989fa" :min-date="minDate" :max-date="maxDate" />
      <van-field label="收案日期" :center="true" :value="shouan" readonly clickable v-show="shouanriqiShow" name="calendar" placeholder="请选择收案日期" @click="shouanShow = true" />
      <van-calendar v-model="shouanShow" @confirm="getShowjiDate" color="#1989fa" :min-date="minDate" :max-date="maxDate" />
      <van-field label="归档日期" :center="true" :value="guidang" readonly clickable v-show="guidangriqiShow" name="calendar" placeholder="请选择归档日期" @click="guidangShow = true" />
      <van-calendar v-model="guidangShow" @confirm="getGuidangDate" color="#1989fa" :min-date="minDate" :max-date="maxDate" />
      <van-field v-model="form.filingPerson" name="立卷人" label="立卷人" placeholder="请输入立卷人" :center="true" />
      <van-field v-model="form.caseNo" name="律所案号" label="律所案号" placeholder="请输入律所案号" :center="true" />
    </van-form>
    <div style="margin: auto">
      <van-button type="info" @click="nextStep" style="width: 90vw; line-height: 0.1rem; border-radius: 0.15rem" v-show="!showLoading && !caipanriqi && !shouanShow && !guidangShow">下一步</van-button>
    </div>
    <van-popup closeable :close-on-click-overlay="false" v-model="flag" mode="center" :style="{ height: '70%', width: '85%' }">
      <div class="pop">
        <div class="pop_p">
          <p class="pop_topic">请选择当前委托人或委托律师</p>
        </div>
        <div class="pop_C">
          <div v-for="(value, index) in precedentsData.litigantList" :key="index">
            <div v-show="value.agentList.length">
              <p class="pop_end">{{ value.litigantIdentType }}:{{ value.name }}</p>
              <div v-for="(val, ind) in value.agentList" :key="ind" style="padding: 0.2rem" @click="changeWt(index, ind, val.name)">
                <input type="checkbox" style="zoom: 150%; margin-right: 0.04rem" :checked="(index === current && ind === currentOut) || val.name == wtName" @change="changeChecked($event)" />
                <span>代理律师：{{ val.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom_box"><van-button type="info" @click="changePop" style="width: 75vw; border-radius: 0.15rem">进行订卷</van-button></div>
      </div>
    </van-popup>
  </div>
</template>
<script>
/* eslint-disable */
import { Notify, Loading, Overlay } from 'vant'
import { mapState } from 'vuex'
import { pydataBase, baseURL } from '~api-config'
export default {
  components: {},
  data() {
    return {
      showLoading: true,
      showPopover: false,
      actions: [{ text: '选项一' }, { text: '选项二' }, { text: '选项三' }],
      checked: false,
      flag: false,
      guidangShow: false,
      shouanShow: false,
      caipanriqi: false,
      form: {
        acceptCaseTime: '', // 收案日期
        filingDate: '', // 归档日期
        case_type: '请选择',
        case_writ: '请选择',
        filingPerson: '', //   立卷人
        proceeding: '请选择', //   审理程序
        judge_date: '', //   裁判日期
        caseReasons: [
          //   案由组
          //    {
          //   	causeName: '',
          //  	caseReasonId: ''
          //  }
        ],
        causeName: '',
        caseNo: '',
        //  litigantList: [], // 案件当事人集合
      },
      inputCauseName: '',
      formPop: {},
      anjianleixingShow: true,
      wenshuxingzhiShow: true,
      shenlichengxuShow: true,
      caipanriqiShow: true,
      guidangriqiShow: true,
      shouanriqiShow: true,
      anyouShow: true,
      precedentsData: {}, //  提取文章段落的内容
      independentDocumentId: 0,
      shareCaseJson: '',
      ygData: [],
      rangeCase_type: [
        {
          value: '民事',
          text: '民事',
        },
        {
          value: '刑事',
          text: '刑事',
        },
        {
          value: '行政',
          text: '行政',
        },
        {
          value: '执行',
          text: '执行',
        },
        {
          value: '交通事故',
          text: '交通事故',
        },
        {
          value: '劳动仲裁',
          text: '劳动仲裁',
        },
        {
          value: '行政复议',
          text: '行政复议',
        },
        {
          value: '商业仲裁',
          text: '商业仲裁',
        },
        {
          value: '法律顾问',
          text: '法律顾问',
        },
        {
          value: '咨询代书',
          text: '咨询代书',
        },
        {
          value: '法律援助',
          text: '法律援助',
        },
        {
          value: '破产案件',
          text: '破产案件',
        },
        {
          value: '国家赔偿(行政赔偿)',
          text: '国家赔偿(行政赔偿)',
        },
        {
          value: '国家赔偿(司法赔偿)',
          text: '国家赔偿(司法赔偿)',
        },
        {
          value: '强制清算与破产',
          text: '强制清算与破产',
        },
        {
          value: '其他非诉',
          text: '其他非诉',
        },
      ],
      rangeCase_writ: [
        {
          value: '判决书',
          text: '判决书',
        },
        {
          value: '裁定书',
          text: '裁定书',
        },
        {
          value: '调解书',
          text: '调解书',
        },
      ],
      rangeProceeding: [
        {
          value: '一审',
          text: '一审',
        },
        {
          value: '二审',
          text: '二审',
        },
        {
          value: '再审',
          text: '再审',
        },
      ],
      showPop: false,
      jsoninfo: {},
      currentOut: -1,
      current: -1,
      companyList: [],
      selectInputData: {},
      nameInput: {
        key: '',
        i: '',
      },
      surface: [], //  对照表
      guidang: '', //  归档日期
      shouan: '', //  收案日期
      caipan: '', //  裁判
      companyTimer: null,
      onId: '',
      handlerReturn: true, //  当点击其确定后跳转页面，在返回时不添加
      showPicker: false, // 控制案由关联组件是否显示
      minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 20)),
      maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 20)),
      changeUSB: null,
      wtName: '',
    }
  },
  computed: {
    ...mapState('user', ['userInfo', 'userinfoAll']),
    ...mapState('dingjuan', ['tempText']),
  },
  methods: {
    back() {
      this.$router.back()
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    },
    // 选择归档日期
    getGuidangDate(e) {
      this.form.filingDate = this.formatDate(e)
      this.guidangShow = false
      this.guidang = this.formatDate(e)
    },
    // 选择收案日期
    getShowjiDate(e) {
      this.form.acceptCaseTime = e.getTime()
      this.shouanShow = false
      this.shouan = this.formatDate(e)
    },
    // 处理时间
    changeTime(val, num) {
      const timeDate = new Date(val).getTime() + num
      let date = new Date(timeDate) // 将时间戳转换为毫秒并创建Date对象
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      // 格式化为YYYY-MM-DD的字符串
      return year + '/' + (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day)
    },
    // 选择裁判日期
    getCaipanDate(date) {
      this.form.judge_date = this.formatDate(date)
      this.caipanriqi = false
      this.caipan = this.formatDate(date)
      const acceptCaseTimes = this.changeTime(this.form.judge_date, -7776000000)
      const filingDates = this.changeTime(this.form.judge_date, -864000000)
      // 收案日期
      if (!this.shouan) {
        this.shouan = acceptCaseTimes
        this.form.acceptCaseTime = new Date(acceptCaseTimes).getTime()
      }
      // 归档日期
      if (!this.guidang) {
        this.form.filingDate = filingDates
        this.guidang = filingDates
      }
    },
    // 解析裁判日期
    caipanriqiFor(value) {
      if (value) {
        if (value.length > 8) {
          // 裁判日期
          this.form.judge_date = value
          this.caipan = value
          this.caipanriqiShow = false
          const acceptCaseTimes = this.changeTime(value, -7776000000)
          const filingDates = this.changeTime(value, -864000000)
          // 收案日期
          this.shouan = acceptCaseTimes
          this.form.acceptCaseTime = new Date(acceptCaseTimes).getTime()
          // 归档日期
          this.form.filingDate = filingDates
          this.guidang = filingDates
        }
      } else {
        this.caipanriqiShow = true
      }
    },
    // 根据内容获取当事人信息
    getDangshirenInfo(e) {
      const data = {
        content: e,
        user_id: this.userInfo.id,
      }
      this.$axios.post(`${pydataBase}/es/precedents/parse`, data).then((res) => {
        // this.$axios.post(`https://data.dev.farbun.com/es/precedents/parse`, data).then((res) => {
        if (res.data.code == 200) {
          this.showLoading = false
          const data = res.data.data
          this.precedentsData = data
          this.initData()
          this.anyoufon(data.causeName)
          this.caipanriqiFor(data.judge_date)
          this.shenlichengxuFor(data.proceeding)
          this.anjianleixingFor(data.case_type)
          this.wenshuxingzhiFor(data.case_writ)
          if (!this.precedentsData.litigantList.some((item) => item?.agentList.length > 0)) {
            Notify({ type: 'danger', message: '未识别到委托律师，请重试' })
          }
          if (this.precedentsData.litigantList.length === 0) {
            Notify({ type: 'danger', message: '未获取到当事人信息' })
            this.$router.push({
              path: '/dingjuan/imgText',
            })
          }
        }
        // else {
        //   Notify({ type: 'danger', message: res.data.message })
        //   this.$router.push({
        //     path: '/dingjuan',
        //   })
        // }
      })
    },
    // 从公开文书进入时执行的代码
    fil(val) {
      const params = {
        doc_id: val.docId,
        type: 1,
      }
      this.$axios.get(`${pydataBase}/es/precedents`, { params }).then((res) => {
        if (res.data.code === 200) {
          this.showLoading = false
          const dataBase = res.data.data
          this.precedentsData = dataBase
          this.initData()
          this.anyoufon(dataBase.causeName)
          this.caipanriqiFor(dataBase.judge_date)
          this.shenlichengxuFor(dataBase.proceeding)
          this.anjianleixingFor(dataBase.case_type)
          this.wenshuxingzhiFor(dataBase.case_writ)
        }
      })
    },
    // 案由
    anyoufon(value) {
      if (value) {
        this.form.caseReasons.push({
          causeName: value,
        })
        this.anyouShow = false
      } else {
        this.anyouShow = true
      }
    },
    // 审理程序
    shenlichengxuFor(value) {
      if (value) {
        this.form.proceeding = value
        this.shenlichengxuShow = false
      } else {
        this.shenlichengxuShow = true
        this.form.proceeding = '请选择'
      }
    },
    // 案件类型
    anjianleixingFor(value) {
      if (value) {
        this.form.case_type = value
        this.anjianleixingShow = false
      } else {
        this.anjianleixingShow = true
        this.form.case_type = '请选择'
      }
    },
    // 文本性质
    wenshuxingzhiFor(value) {
      if (value) {
        this.form.case_writ = value
        this.wenshuxingzhiShow = false
      } else {
        this.wenshuxingzhiShow = true
        this.form.case_writ = '请选择'
      }
    },
    // 修改案件类型下拉框快
    changeCase_type(e) {
      this.form.case_type = e
    },
    // 修改文书性质下拉框快
    changeCase_writ(e) {
      this.form.case_writ = e
    },
    // 修改审理程序下拉框快
    changeProceeding(e) {
      this.form.proceeding = e
    },
    changeProcedure(index, e) {
      this.form.litigantList[index].name = e
    },
    // 选择委托人
    changeWt(index, ind, e) {
      if (this.precedentsData.litigantList[index].agentList[ind].name === e) {
        this.current = index
        this.currentOut = ind
        this.wtName = e

        this.form.litigantList.forEach((ele) => {
          ele.displayClient = 'NO'
        })
        for (let i = 0; i < this.precedentsData.litigantList.length; i++) {
          for (let y = 0; y < this.precedentsData.litigantList[i].agentList.length; y++) {
            if (this.precedentsData.litigantList[i].agentList[y].name === e) {
              this.form.litigantList[i].displayClient = 'YES'
              this.form.agentListsFn = { ...this.form.litigantList[i].agentList[y] }
              this.form.procedureSubject = this.form.litigantList[i].hisIdentityType
            }
          }
        }
        console.log(123, this.form)
      }
    },
    changeChecked(e) {
      if (!e.target.checked) {
        this.current = -1
        this.currentOut = -1
        this.wtName = ''
      }
    },
    inputName(e) {
      if (this.companyTimer) return
      if (e.length > 1) {
        this.companyTimer = setTimeout(() => {
          this.vagueQueryCaseReason(e)
        }, 300)
      }
      if (e.length == 0) {
        this.companyList = []
      }
    },
    vagueQueryCaseReason(data) {
      this.companyList = []
      const params = { name: data }
      this.$axios.get(`${baseURL}/lts/commonCaseReason/vagueQueryCaseReason`, { params }).then((res) => {
        this.companyTimer = null
        if (res.data.code == 200) {
          if (res.data.data.length) {
            res.data.data.forEach((item) => {
              this.companyList.push({ text: item.name, textId: item.caseReasonId })
              this.showPicker = true
            })
          } else {
            this.inputCauseName = ''
            Notify({ type: 'danger', message: '未匹配到案由' })
          }
        } else {
          this.inputCauseName = ''
        }
      })
    },
    blurName() {
      setTimeout(() => {
        this.companyList = []
        this.companyTimer = null
        this.inputCauseName = ''
      }, 300)
    },
    /**
     * @description
     * @param {Object} data 选中的案由信息
     */
    selectCompany(data) {
      this.showPicker = false
      this.inputCauseName = ''
      this.form.caseReasons.push({
        caseReasonId: data.textId,
        causeName: data.text,
      })
      this.companyList = []
    },
    selectCompanyFalse() {
      this.companyList = []
      this.inputCauseName = ''
    },
    // 点击遮罩层遮罩层
    clickOverlay() {
      this.inputCauseName = ''
      this.showPicker = false
    },
    // 删除标签
    tagClick(index) {
      this.form.caseReasons.splice(index, 1)
    },
    getDocumentTemplate(type, trialProcedure) {
      const data = {
        groupId: 298,
        documentId: 235,
        caseType: type,
        trialProcedure: trialProcedure,
      }
      this.$axios.post(`${baseURL}/document/basicDocuments/getDocumentHtml2`, data).then((res) => {
        this.form.caseNo = JSON.parse(res.data.data.sharedCaseJSON).caseNo
        this.jsoninfo = JSON.parse(res.data.data.caseInfoJson)
        this.independentDocumentId = res.data.data.independentDocumentId
      })
    },
    /**
     * @description  修改保存文书信息
     */
    saveOrUpload(caseInfoJson) {
      const data = {
        caseInfoJson,
        doc: this.precedentsData.doc, // 文章id
        groupId: 298,
        id: this.independentDocumentId,
      }
      this.$axios.post(`${baseURL}/document/getDocumentCaseJson/saveOrUpdateCaseJson`, data).then((res) => {
        if (res.data.code === '200') {
          this.handlerReturn = false
          this.showPop = false
          this.$router.push({
            name: 'fileDownlaod',
            query: {
              independentDocumentId: this.independentDocumentId,
            },
          })
        }
      })
    },
    // 归档
    pigeonholeWrit(e) {
      const data = [e]
      const pigeonholeWrit = `${baseURL}/mq/lbLawyerPrecedent/archive`
      this.$axios({
        url: pigeonholeWrit,
        method: 'POST',
        data,
      })
    },
    addLocalUploadDataBaes(val) {
      const info = this.changeUSB.lawyer
      console.log(321, this.changeUSB)
      let userId
      let lawyerId
      let nameId
      const u = navigator.userAgent

      if (info) {
        userId = info.userId
        lawyerId = info.id
        nameId = info.lawyerName
      } else {
        userId = this.changeUSB?.user?.id
        lawyerId = ''
        nameId = ''
      }
      if (u === 'fb-Android-Webview') {
        console.log('u')
      } else if (u === 'fb-iOS-Webview') {
        console.log('u')
      } else if (u === 'fb-flutter-Webview') {
        console.log('u')
      } else {
        userId = this.changeUSB.id
      }
      const data = {
        userId: userId,
        lawyerId: lawyerId,
        lawyerName: nameId,
        precedentsJson: '',
        docId: this.precedentsData.doc_id,
        caseId: val.case_id,
        caseName: val.case_name,
        caseAttr: '普通案例',
        caseType: val.case_type,
        caseWrit: val.case_writ,
        summary: val.causeName,
        judgeDate: val.judge_date,
        proceeding: val.proceeding,
        independentDocumentId: this.independentDocumentId,
      }
      console.log(data, '处理后的data')
      this.$axios.post(`${baseURL}/mq/lbLawyerPrecedent/addLocalUploadData`, data).then((res) => {
        this.pigeonholeWrit(res.data.data)
      })
    },
    // 进行归档的操作
    changePop() {
      if (this.current === -1 || this.currentOut === -1) {
        Notify({ type: 'danger', message: '请选择归档律师' })
        return
      }
      for (const key in this.form) {
        this.jsoninfo[key] = this.form[key]
      }
      this.surface.forEach((value) => {
        if (value.caseType == this.jsoninfo.case_type) {
          this.jsoninfo.litigantList.forEach((val, ind) => {
            if (val.litigantIdentType == value.titleName) {
              this.jsoninfo.litigantList[ind].procedureSubject = value.procedureSubject
              this.jsoninfo.litigantList[ind].procedureSubjectKeyId = 10006
              this.jsoninfo.litigantList[ind].procedureSubjectValueId = 10017
            }
          })
        }
      })
      // this.jsoninfo.litigantList.forEach((ele, index) => {
      //   ele.agentList.forEach((agentItem) => {
      //     if (agentItem.agencyAuthority === '特别授权，代为承认、变更、放弃诉讼请求，和解、上诉或反诉，代收法律文书') {
      //       agentItem.agencyAuthorityContent = '特别授权，代为承认、变更、放弃诉讼请求，和解、上诉或反诉，代收法律文书'
      //     }
      //   })
      // })
      if (this.form.caseReasons.length == 1) {
        this.jsoninfo.originalCauseName = this.form.caseReasons[0].causeName
        this.jsoninfo.causeName = this.form.caseReasons[0].causeName
      } else {
        this.jsoninfo.originalCauseName = this.form.caseReasons[0].causeName + '、' + this.form.caseReasons[1].causeName
        this.jsoninfo.causeName = this.form.caseReasons[0].causeName + '、' + this.form.caseReasons[1].causeName
      }
      this.jsoninfo.caseName = this.precedentsData.case_name
      this.jsoninfo.briefCaseTexts = ['', ...this.precedentsData.briefCaseTextArr]
      // 添加文书
      if (this.handlerReturn) {
        this.addLocalUploadDataBaes(this.jsoninfo)
      } else {
        this.$axios.post(`${baseURL}/mq/lbLawyerPrecedent/archive`, [this.onId]).then((res) => {
          if (res.code == 200) {
            Notify({ type: 'success', message: '归档成功' })
          }
        })
        const data = {
          id: this.onId,
          independentDocumentId: this.independentDocumentId,
        }
        this.$axios.post(`${baseURL}/mq/lbLawyerPrecedent/updateById`, data)
      }
      // 修改casejson
      this.saveOrUpload(JSON.stringify(this.jsoninfo))
    },
    /**
     * @description弹出框的操作，进入pc端短信页面。
     */
    nextStep() {
      if (!this.form.case_type === '请选择') {
        Notify({ type: 'danger', message: '请选择案件类型' })
        return
      }
      if (!this.form.case_writ === '请选择') {
        Notify({ type: 'danger', message: '请选择文书性质' })
        return
      }
      if (this.form.proceeding === '请选择') {
        Notify({ type: 'danger', message: '请选择审理程序' })
        return
      }
      if (!this.form.caseReasons.length) {
        Notify({ type: 'danger', message: '请填写案由' })
        return
      }
      if (!this.form.judge_date) {
        Notify({ type: 'danger', message: '请填写裁判日期' })
        return
      }
      const data = {
        caseType: this.precedentsData.case_type,
        primaryProcedure: this.precedentsData.proceeding,
      }
      this.showPop = true
      this.$axios.post(`${baseURL}/document/BasicCaseLitigants/getList`, data).then((res) => {
        this.surface = res.data.data
      })

      if (this.precedentsData.litigantList.length) {
        for (let index = 0; index < this.precedentsData.litigantList.length; index++) {
          if (this.precedentsData.litigantList[index].agentList.length > 0) {
            this.flag = true
            return
          }
        }
      } else {
        Notify({ type: 'danger', message: '未识别到委托律师，请重试' })
      }
      if (!this.precedentsData.litigantList.some((item) => item?.agentList.length > 0)) {
        Notify({ type: 'danger', message: '未识别到委托律师，请重试' })
      }
    },

    // 初始化数据
    initData() {
      this.getDocumentTemplate(this.precedentsData.case_type, this.precedentsData.proceeding)
      for (const key in this.precedentsData) {
        this.form[key] = this.precedentsData[key]
      }
      if (this.precedentsData.court_date) {
        const court_date = new Date(this.precedentsData.court_date.replace(/[年|月]/g, '/').replace(/日/, '')).getTime()
        const acceptCaseTimes = this.changeTime(court_date, -7776000000)
        this.form.acceptCaseTime = new Date(acceptCaseTimes).getTime()
        this.shouan = this.changeTime(court_date, -7776000000)
        const filingDates = this.changeTime(court_date, 864000000)
        this.form.filingDate = filingDates
        this.guidang = this.changeTime(court_date, 864000000)
      }
      // 立卷人
      const info = this.changeUSB.lawyer
      this.form.statementTexts = []
      if (!this.precedentsData?.litigantList?.length) return
      this.precedentsData?.litigantList?.forEach((ele) => {
        this.ygData.push({
          displayClient: '', // 委托人YSE 当事人NO
          category: ele.category || 'NATURAL_PERSON', // 身份类型【自然人,法人,非法人】
          idNumber: ele.idNumber || '', // 身份证号
          phone: ele.phone || '', // 联系电话
          address: ele.address || '', // 住址
          sex: ele.sex || '', // 性别
          birthday: ele.birthday || '', // 生日
          nation: ele.nation || '', // 名族
          agents: '', // 代理人
          documentDelivery: ele.documentDelivery || '', // 文书送达地
          postalCode: ele.postalCode || '', // 邮政编码
          otherPhone: ele.otherPhone || '', // 其他联系电话
          manager: ele.legal_represent && ele.legal_represent.length > 0 ? ele.legal_represent.map((r) => r.name).join('、') : '', // 法定代表人 主要负责人
          position: ele.position || (ele.legal_represent && ele.legal_represent[0] ? ele.legal_represent[0].position : ''), // 职务
          litigantIdentType: ele.litigantIdentType || '',
          agentList: [],
        })
        if (ele.agentList && ele.agentList.length > 0) {
          ele.agentList.forEach((agentListItem) => {
            this.ygData[this.ygData.length - 1].agentList.push({
              agencyAuthority: agentListItem.agencyAuthority || 'SPECIAL_AGENT', // 代理人选择 特别授权、一般代理
              agencyType: agentListItem.agencyType || '', // 律师、法律工作者、其他
              lawFirmName: agentListItem.lawFirmName || '',
              // isDefaultAgent: true,
              lawfirmId: '',
              lawyerId: '',
              name: agentListItem.name || '',
            })
            if (info) {
              if (agentListItem.name === info?.lawyerName && agentListItem.lawFirmName === info.lawFirmName && ele.opinion.length) {
                this.form.statementTexts.push(...ele.opinion)
              }
            }
          })
        }
      })
      if (info) {
        const now_name = info?.lawyerName
        const now_lawFirmName = info.lawFirmName
        this.ygData.forEach((ele) => {
          ele.agentList.forEach((child) => {
            if (child.lawFirmName === now_lawFirmName && child.name === now_name) {
              ele.agents = now_name
            }
          })
        })
        // 设置立卷人
        this.form.filingPerson = now_name
      }
    },
  },
  created() {
    this.changeUSB = JSON.parse(this.$route.query.changeUSB)
  },
  beforeMount() {},
  mounted() {
    if (this.$route.query.comeWel === 'index') {
      this.welCome = false
      this.onId = this.$route.query.option.id
      this.fil(this.$route.query.option)
      this.handlerReturn = false
    } else {
      const res = this.tempText.split('\n')
      this.getDangshirenInfo(res)
    }
  },
}
</script>
<style scoped>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bottom_box {
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
}

.law_firm {
  position: absolute;
  width: 100%;
  height: 400rpx;
  padding: 10rpx 5rpx;
  background: #fff;
  border: 1px solid #eee;
  z-index: 999;
}

.pop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pop_topic {
  margin: 0.5rem 0;
  font-size: 0.4rem;
}

.pop_end {
  padding: 0.2rem 0;
  width: 7rem;
  text-align: center;
}

.pop_C {
  font-size: 0.4rem;
  border: 1px solid rgba(51, 51, 51, 1);
  width: 80vw;
  height: 52vh;
  overflow-y: scroll;
  border-radius: 5px;
}

.popupStyle {
  z-index: 9999;
  position: absolute;
  top: 1.55rem;
  right: 0.3rem;
  background-color: white;
  height: 10rem;
  width: 9.5rem;
  overflow-y: scroll;
  font-size: 14px;
  border-radius: 5px;
}

.sanjao {
  z-index: 100;
  content: '';
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent rgb(255, 255, 255) transparent;
  position: absolute;
  left: 48%;
  top: 1.3rem;
}

.liStyle {
  padding: 0.15rem;
  width: 6rem;
  word-break: break-all;
}

.law_tag {
  display: flex;
  flex-wrap: wrap;
}

.law_tag:after {
  content: '';
  flex: auto;
}

::v-deep .van-button--round {
  border-radius: 0.1rem;
}

::v-deep .van-field {
  width: 95vw;
  border-bottom: 1px solid #d8d8d8;
}
</style>
