var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.selectCompanyFalse.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "fb-header",
        {
          staticStyle: { "text-align": "left" },
          attrs: { slot: "header", title: "确认信息" },
          slot: "header",
        },
        [
          _c("van-icon", {
            staticClass: "arrow",
            attrs: { slot: "left", name: "arrow-left" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.back.apply(null, arguments)
              },
            },
            slot: "left",
          }),
        ],
        1
      ),
      _vm.showLoading
        ? _c(
            "van-loading",
            {
              staticStyle: { "margin-top": "100px" },
              attrs: {
                type: "spinner",
                size: "80px",
                vertical: "",
                "text-size": "20px",
              },
            },
            [_vm._v("加载中...")]
          )
        : _c(
            "van-form",
            {
              ref: "uForm",
              staticStyle: {
                "border-bottom": "1px solid rgba(153, 153, 153, 1)",
                height: "85vh",
                "overflow-y": "scroll",
                "padding-left": "0.2rem",
              },
              attrs: { model: _vm.form, "label-width": "2rem" },
            },
            [
              _c("van-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.anjianleixingShow,
                    expression: "anjianleixingShow",
                  },
                ],
                attrs: {
                  name: "switch",
                  label: "案件类型",
                  center: true,
                  required: true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "input",
                    fn: function () {
                      return [
                        _c(
                          "van-dropdown-menu",
                          {
                            staticStyle: { width: "90%" },
                            attrs: {
                              title: "案件类型",
                              "active-color": "#1890FF",
                              overlay: "",
                            },
                          },
                          [
                            _c("van-dropdown-item", {
                              attrs: {
                                options: _vm.rangeCase_type,
                                title: _vm.form.case_type || "请选择",
                              },
                              on: { change: _vm.changeCase_type },
                              model: {
                                value: _vm.form.case_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "case_type", $$v)
                                },
                                expression: "form.case_type",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("van-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.shenlichengxuShow,
                    expression: "shenlichengxuShow",
                  },
                ],
                attrs: {
                  label: "审理程序",
                  border: true,
                  required: true,
                  center: true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "input",
                    fn: function () {
                      return [
                        _c(
                          "van-dropdown-menu",
                          {
                            staticStyle: { width: "90%" },
                            attrs: {
                              title: "审理程序",
                              "active-color": "#1890FF",
                              overlay: "",
                            },
                          },
                          [
                            _c("van-dropdown-item", {
                              attrs: {
                                options: _vm.rangeProceeding,
                                title: _vm.form.proceeding || "请选择",
                              },
                              on: { change: _vm.changeProceeding },
                              model: {
                                value: _vm.form.proceeding,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "proceeding", $$v)
                                },
                                expression: "form.proceeding",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("van-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.wenshuxingzhiShow,
                    expression: "wenshuxingzhiShow",
                  },
                ],
                attrs: { label: "文书性质", required: true, center: true },
                scopedSlots: _vm._u([
                  {
                    key: "input",
                    fn: function () {
                      return [
                        _c(
                          "van-dropdown-menu",
                          {
                            staticStyle: { width: "90%" },
                            attrs: {
                              title: "文书性质",
                              "active-color": "#1890FF",
                              overlay: "",
                            },
                          },
                          [
                            _c("van-dropdown-item", {
                              attrs: {
                                options: _vm.rangeCase_writ,
                                title: _vm.form.case_writ || "请选择",
                              },
                              on: { change: _vm.changeCase_writ },
                              model: {
                                value: _vm.form.case_writ,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "case_writ", $$v)
                                },
                                expression: "form.case_writ",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.anyouShow,
                      expression: "anyouShow",
                    },
                  ],
                  staticStyle: { position: "relative" },
                },
                [
                  _c("van-field", {
                    attrs: {
                      name: "案由",
                      label: "案由",
                      required: true,
                      placeholder: "请输入案由",
                      center: true,
                    },
                    on: {
                      onBlur: function ($event) {
                        return _vm.blurName()
                      },
                      input: function ($event) {
                        return _vm.inputName($event)
                      },
                    },
                    model: {
                      value: _vm.inputCauseName,
                      callback: function ($$v) {
                        _vm.inputCauseName = $$v
                      },
                      expression: "inputCauseName",
                    },
                  }),
                  _vm.companyList.length
                    ? _c(
                        "ul",
                        { staticClass: "popupStyle" },
                        _vm._l(_vm.companyList, function (item) {
                          return _c(
                            "li",
                            {
                              key: item.text,
                              staticClass: "liStyle",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.selectCompany(item)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.text) + " ")]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.companyList.length,
                        expression: "companyList.length",
                      },
                    ],
                    staticStyle: {
                      width: "110%",
                      height: "95vh",
                      "background-color": "rgba(0, 0, 0, 0.3)",
                      position: "fixed",
                      "z-index": "1",
                      top: "1.2rem",
                      left: "0",
                    },
                  }),
                  _vm.companyList.length
                    ? _c("div", { staticClass: "sanjao" })
                    : _vm._e(),
                ],
                1
              ),
              _vm.form.caseReasons.length
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.anyouShow,
                          expression: "anyouShow",
                        },
                      ],
                      staticClass: "law_tag",
                    },
                    _vm._l(_vm.form.caseReasons, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticStyle: { padding: "0.1rem" } },
                        [
                          _c(
                            "van-tag",
                            {
                              attrs: {
                                mode: "plain",
                                type: "primary",
                                closeable: "",
                              },
                              on: {
                                close: function ($event) {
                                  return _vm.tagClick(index)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item.causeName) + " ")]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._l(_vm.precedentsData.litigantList, function (value, index) {
                return _c(
                  "van-field",
                  {
                    key: index,
                    attrs: { label: value.litigantIdentType, required: true },
                    on: {
                      input: function ($event) {
                        return _vm.changeProcedure(index, $event)
                      },
                    },
                    model: {
                      value: value.name,
                      callback: function ($$v) {
                        _vm.$set(value, "name", $$v)
                      },
                      expression: "value.name",
                    },
                  },
                  [
                    _c("div", {
                      staticStyle: {
                        width: "97%",
                        height: "1px",
                        "background-color": "#d8d8d8",
                      },
                    }),
                  ]
                )
              }),
              _c("van-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.caipanriqiShow,
                    expression: "caipanriqiShow",
                  },
                ],
                attrs: {
                  label: "裁判日期",
                  value: _vm.caipan,
                  readonly: "",
                  clickable: "",
                  name: "calendar",
                  placeholder: "请选择裁判日期",
                  center: true,
                  required: true,
                },
                on: {
                  click: function ($event) {
                    _vm.caipanriqi = true
                  },
                },
              }),
              _c("van-calendar", {
                attrs: {
                  color: "#1989fa",
                  "min-date": _vm.minDate,
                  "max-date": _vm.maxDate,
                },
                on: { confirm: _vm.getCaipanDate },
                model: {
                  value: _vm.caipanriqi,
                  callback: function ($$v) {
                    _vm.caipanriqi = $$v
                  },
                  expression: "caipanriqi",
                },
              }),
              _c("van-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.shouanriqiShow,
                    expression: "shouanriqiShow",
                  },
                ],
                attrs: {
                  label: "收案日期",
                  center: true,
                  value: _vm.shouan,
                  readonly: "",
                  clickable: "",
                  name: "calendar",
                  placeholder: "请选择收案日期",
                },
                on: {
                  click: function ($event) {
                    _vm.shouanShow = true
                  },
                },
              }),
              _c("van-calendar", {
                attrs: {
                  color: "#1989fa",
                  "min-date": _vm.minDate,
                  "max-date": _vm.maxDate,
                },
                on: { confirm: _vm.getShowjiDate },
                model: {
                  value: _vm.shouanShow,
                  callback: function ($$v) {
                    _vm.shouanShow = $$v
                  },
                  expression: "shouanShow",
                },
              }),
              _c("van-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.guidangriqiShow,
                    expression: "guidangriqiShow",
                  },
                ],
                attrs: {
                  label: "归档日期",
                  center: true,
                  value: _vm.guidang,
                  readonly: "",
                  clickable: "",
                  name: "calendar",
                  placeholder: "请选择归档日期",
                },
                on: {
                  click: function ($event) {
                    _vm.guidangShow = true
                  },
                },
              }),
              _c("van-calendar", {
                attrs: {
                  color: "#1989fa",
                  "min-date": _vm.minDate,
                  "max-date": _vm.maxDate,
                },
                on: { confirm: _vm.getGuidangDate },
                model: {
                  value: _vm.guidangShow,
                  callback: function ($$v) {
                    _vm.guidangShow = $$v
                  },
                  expression: "guidangShow",
                },
              }),
              _c("van-field", {
                attrs: {
                  name: "立卷人",
                  label: "立卷人",
                  placeholder: "请输入立卷人",
                  center: true,
                },
                model: {
                  value: _vm.form.filingPerson,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "filingPerson", $$v)
                  },
                  expression: "form.filingPerson",
                },
              }),
              _c("van-field", {
                attrs: {
                  name: "律所案号",
                  label: "律所案号",
                  placeholder: "请输入律所案号",
                  center: true,
                },
                model: {
                  value: _vm.form.caseNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "caseNo", $$v)
                  },
                  expression: "form.caseNo",
                },
              }),
            ],
            2
          ),
      _c(
        "div",
        { staticStyle: { margin: "auto" } },
        [
          _c(
            "van-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    !_vm.showLoading &&
                    !_vm.caipanriqi &&
                    !_vm.shouanShow &&
                    !_vm.guidangShow,
                  expression:
                    "!showLoading && !caipanriqi && !shouanShow && !guidangShow",
                },
              ],
              staticStyle: {
                width: "90vw",
                "line-height": "0.1rem",
                "border-radius": "0.15rem",
              },
              attrs: { type: "info" },
              on: { click: _vm.nextStep },
            },
            [_vm._v("下一步")]
          ),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          style: { height: "70%", width: "85%" },
          attrs: {
            closeable: "",
            "close-on-click-overlay": false,
            mode: "center",
          },
          model: {
            value: _vm.flag,
            callback: function ($$v) {
              _vm.flag = $$v
            },
            expression: "flag",
          },
        },
        [
          _c("div", { staticClass: "pop" }, [
            _c("div", { staticClass: "pop_p" }, [
              _c("p", { staticClass: "pop_topic" }, [
                _vm._v("请选择当前委托人或委托律师"),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "pop_C" },
              _vm._l(_vm.precedentsData.litigantList, function (value, index) {
                return _c("div", { key: index }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: value.agentList.length,
                          expression: "value.agentList.length",
                        },
                      ],
                    },
                    [
                      _c("p", { staticClass: "pop_end" }, [
                        _vm._v(
                          _vm._s(value.litigantIdentType) +
                            ":" +
                            _vm._s(value.name)
                        ),
                      ]),
                      _vm._l(value.agentList, function (val, ind) {
                        return _c(
                          "div",
                          {
                            key: ind,
                            staticStyle: { padding: "0.2rem" },
                            on: {
                              click: function ($event) {
                                return _vm.changeWt(index, ind, val.name)
                              },
                            },
                          },
                          [
                            _c("input", {
                              staticStyle: {
                                zoom: "150%",
                                "margin-right": "0.04rem",
                              },
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked:
                                  (index === _vm.current &&
                                    ind === _vm.currentOut) ||
                                  val.name == _vm.wtName,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.changeChecked($event)
                                },
                              },
                            }),
                            _c("span", [
                              _vm._v("代理律师：" + _vm._s(val.name)),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ])
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "bottom_box" },
              [
                _c(
                  "van-button",
                  {
                    staticStyle: { width: "75vw", "border-radius": "0.15rem" },
                    attrs: { type: "info" },
                    on: { click: _vm.changePop },
                  },
                  [_vm._v("进行订卷")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }