<template>
  <div>
    <div class="comenm">
      <van-tabs v-model="localOrLine" style="width: 70%; padding-top: 10px" @change="toggles" color="#1890FF" title-active-color="#1890FF">
        <van-tab title="公开文书" name="0"> </van-tab>
        <van-tab title="本地文书" name="1"> </van-tab>
      </van-tabs>
      <van-collapse v-model="activeNames" class="collapseStyle">
        <van-collapse-item ref="checkbox" :title="title" name="1" style="font-size: 18px">
          <div @click="collapseItemHanlder('2', '全部')" style="color: black; font-size: 18px">全部</div>
          <div @click="collapseItemHanlder('0', '未归档')" style="color: black; font-size: 18px">未归档</div>
          <div @click="collapseItemHanlder('1', '已归档')" style="color: black; font-size: 18px">已归档</div>
        </van-collapse-item>
      </van-collapse>
    </div>
    <div class="liteBox" @scroll="handleScroll" :class="show ? 'listheaight' : 'listheaighted'">
      <van-swipe-cell v-for="(item, index) in dataList" :key="index" class="liStyle">
        <van-cell :border="false" @click.stop="toIndependent(item)">
          <div>
            <span class="borderStyle" v-if="item.isArchive">归档</span>
            <span class="borderStyle" style="border: 1px solid #1890ff; color: #1890ff">{{ item?.caseWrit }}</span>
            <span class="titleStyle">{{ item.caseName }}</span>
          </div>
          <div style="color: #999999; font-size: 0.39rem">
            <span> {{ item.caseId }}/</span>
            <span> {{ item.judgeDate | reverse }}</span>
          </div>
        </van-cell>
        <template #right v-if="localOrLine == 1">
          <van-button style="background-color: red; color: white" text="删除" @click="delWrit(item)" />
        </template>
      </van-swipe-cell>
      <div style="width: 100%; text-align: center" v-if="noMore">没有更多数据</div>
    </div>
    <div class="gonna" @click="takePhotoPop">
      <i class="iconfont icon-a-shuangjiantoushang"></i>
    </div>
    <van-popup v-model="show" round position="bottom" :overlay="false" :style="{ height: '20%', boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.2)' }">
      <div style="width: 18px; height: 8px; margin: auto; margin-top: 19px; color: #1890ff">
        <i class="iconfont icon-a-shuangjiantouxia" @click="takePhotoPop"></i>
      </div>
      <div style="display: flex; justify-content: space-evenly; margin-top: 10%">
        <i @click="relap" class="iconfont icon-a-zu452" style="font-size: 50px; font-size: 1.5rem; margin-left: 1rem; color: #666666"></i>
        <i @click="openFile" class="iconfont icon-wenjian6" style="font-size: 50px; font-size: 1.5rem; color: #666666"></i>
      </div>
    </van-popup>
    <van-popup v-model="showlawyer" round position="bottom" :style="{ height: '70%' }">
      <iframe src="/lawyerAuth?showLay=0" frameborder="0" width="100%" height="100%"></iframe>
    </van-popup>
  </div>
</template>
<script>
/* eslint-disable */
import { caseBase, qiniuBase } from '~api-config'
import { mapState, mapGetters, mapMutations } from 'vuex'
import store from '../../store'
import { Dialog } from 'vant'
export default {
  data() {
    return {
      activeNames: ['1'],
      localOrLine: 0,
      title: '全部',
      show: true, // 是否展示相机弹窗showList
      mediaStream: null,
      photos: [],
      noMore: false, // 是否显示加载更多
      dataList: [],
      dataTotal: 0,
      isArchive: '2', // 全部，已归档，未归档
      current: 1,
      changeUSB: '',
      showlawyer: false,
      scrollContainer: null, // 滚动容器的DOM元素
      isLoading: false, // 是否正在加载新数据
    }
  },
  computed: {
    ...mapState('user', ['userInfo', 'userinfo', 'userinfoAll', 'lawyerFlag']),
    // ...mapGetters('user', ['getLawState']),
  },
  methods: {
    ...mapMutations('dingjuan', ['SET_TEMPTEXT']),
    toggles(e) {
      this.dataList = []
      this.noMore = false
      this.current = 1
      this.localOrLine = e
      this.getLawerCases()
    },
    // 获取文书列表
    getLawerCases() {
      const params =
        this.isArchive !== '2'
          ? {
              current: this.current,
              size: 30,
              isArchive: this.isArchive,
              publicType: this.localOrLine,
            }
          : {
              current: this.current,
              size: 30,
              publicType: this.localOrLine,
            }
      const getCases = `${caseBase}/mq/lbLawyerPrecedent/getCases`
      this.$axios({
        url: getCases,
        method: 'GET',
        params,
      }).then((res) => {
        this.isLoading = false
        if (this.current === 1) {
          this.dataList = res.data.data.records
        } else {
          this.dataList.push(...res.data.data.records)
        }
        this.dataTotal = res.data.data.pages
      })
    },
    // 删除后获取文书列表
    getList() {
      const params =
        this.isArchive !== '2'
          ? {
              current: 1,
              size: 30,
              isArchive: this.isArchive,
              publicType: this.localOrLine,
            }
          : {
              current: 1,
              size: 30,
              publicType: this.localOrLine,
            }
      const getCases = `${caseBase}/mq/lbLawyerPrecedent/getCases`
      this.$axios({
        url: getCases,
        method: 'GET',
        params,
      }).then((res) => {
        this.isLoading = false
        this.dataList = res.data.data.records

        this.dataTotal = res.data.data.pages
      })
    },
    //删除
    delWrit(e) {
      Dialog.confirm({
        message: '确定删除文书',
      })
        .then(() => {
          const params = {
            id: e.id,
          }
          const delWrit = `${caseBase}/mq/lbLawyerPrecedent/delete`
          this.$axios({
            url: delWrit,
            method: 'GET',
            params,
          }).then((res) => {
            if (res.data.code === 200) {
              this.getList()
              this.$toast('删除成功')
            }
          })
        })
        .catch(() => {})
    },
    toIndependent(e) {
      if (e.independentDocumentId) {
        this.$router.push({
          path: '/dingjuan/fileDownlaod',
          query: {
            independentDocumentId: e.independentDocumentId,
          },
        })
      } else {
        this.$router.push({
          path: '/dingjuan/dangshiren',
          query: {
            option: e,
            changeUSB: JSON.stringify(this.changeUSB),
            comeWel: 'index',
          },
        })
      }
    },
    // ocr回调
    ocrCallback(data) {
      this.SET_TEMPTEXT(data)
      console.log(data)
      this.$router.push({
        path: '/dingjuan/imgText',
      })
    },
    openFile() {
      // if (!this.getLawState) {
      //   this.showlawyer = true
      //   return
      // }
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.getLocalOfficeFileChar()
      } else if (ua === 'fb-flutter-Webview') {
        window.getLocalOfficeFileChar.postMessage('{}')
      } else {
        if (document.getElementById('myIpt')) {
          document.getElementById('myIpt').remove()
        }
        const that = this
        const ipt = document.createElement('input')
        ipt.accept = '.doc,.dot,.pdf,.docx'
        ipt.type = 'file'
        ipt.id = 'myIpt'
        document.body.appendChild(ipt)
        ipt.click()
        ipt.onchange = () => {
          this.$toast.loading({
            message: '解析中...',
            forbidClick: true,
          })
          const file = ipt.files[0]
          const qiniu = new this.$qiniu()
          qiniu.UploadFile(
            'DEFAULT',
            file,
            {
              next: (file) => {},
              complete: (res, file) => {},
              error: () => {
                that.$toast.clear()
                this.$notify('上传失败，请重新操作')
              },
            },
            (fileopt, key, file) => {
              let fileType = 'word'
              if (fileopt.type === 'application/pdf') {
                fileType = 'pdf'
              }
              const url = 'http:' + qiniuBase + '/' + key
              this.$axios
                .post(`${this.$base}/document/convertoffice/getText?url=${url}&type=${fileType}`)
                .then((res) => {
                  that.$toast.clear()
                  if (res.data.code === '200' && res.data.data) {
                    this.SET_TEMPTEXT(res.data.data.join('\n'))
                    this.$router.push({
                      path: '/dingjuan/imgText',
                    })
                  } else {
                    this.$notify('解析失败，请重新操作')
                  }
                })
                .catch((err) => {
                  that.$toast.clear()
                  this.$notify('解析失败，请重新操作')
                })
            }
          )
          ipt.remove()
        }
      }
    },
    officeFileCharResult(data) {
      this.SET_TEMPTEXT(data)
      this.$router.push({
        path: '/dingjuan/imgText',
      })
    },
    collapseItemHanlder(e, value) {
      this.title = value
      this.$refs.checkbox.toggle() // 选择类型自动收起
      this.isArchive = e
      this.getLawerCases()
    },
    async relap() {
      // if (!this.getLawState) {
      //   this.showlawyer = true
      //   return
      // }
      window.SetText = (val) => {
        console.log('window.SetText', val)
      }
      try {
        const u = navigator.userAgent
        if (u === 'fb-Android-Webview') {
          // eslint-disable-next-line
          AND2JS.showAnalyseCamera()
        } else if (u === 'fb-iOS-Webview') {
          // window.webkit.messageHandlers.showCaseCameraV2.postMessage({})
          window.webkit.messageHandlers.showCaseCameraLIN.postMessage({})
        } else if (u === 'fb-flutter-Webview') {
          // window.webkit.messageHandlers.showCaseCameraV2.postMessage({})
          window.showCamera.postMessage('{}')
        } else {
          this.$toast('非原生环境,返回原生指定页')
        }
      } catch (err) {
        this.$notify(JSON.stringify(err))
        console.log(err, 'showCaseCameraLIN1.postMessage')
      }
    },
    takePhotoPop() {
      this.show = !this.show
    },
    handleScroll() {
      const container = this.scrollContainer
      if (container.scrollTop + container.clientHeight + 10 >= container.scrollHeight) {
        this.loadMoreData()
      }
    },
    loadMoreData() {
      console.log(1)
      if (this.isLoading) return
      if (this.dataTotal < this.current) {
        this.noMore = true
        return
      }
      this.isLoading = true
      this.current++
      this.getLawerCases()
    },
  },
  created() {
    // Dialog.confirm({
    //   message: 'created',
    // })
    //   .then(() => {})
    //   .catch(() => {})

    // 关联组件内方法ocrCaseResultCallback 至 JSBridge/officeFileCharResult
    this.$hook(this.$bridge.officeFileCharResult, this.officeFileCharResult)
    // // 组件销毁的时候释放桥接方法
    this.$once('hook:destroyed', () => {
      this.$unhook(this.$bridge.officeFileCharResult)
    })
    window.ocrAnalysis = this.ocrCallback
  },
  mounted() {
    // Dialog.confirm({
    //   message: 'mounted',
    // })
    //   .then(() => {})
    //   .catch(() => {})
    this.$refs.checkbox.toggle()
    this.getLawerCases()
    this.current = 1
    const u = navigator.userAgent
    if (u === 'fb-Android-Webview') {
      this.changeUSB = this.userinfoAll
      // if (this.userinfoAll.lawyer === null) {
      //   this.showlawyer = true
      //   store.commit('user/setLawyerFlag', false)
      // } else {
      //   store.commit('user/setLawyerFlag', true)
      //   this.showlawyer = false
      // }
      window.getLoginInfo.postMessage('{}')
    } else if (u === 'fb-iOS-Webview') {
      this.changeUSB = this.userinfoAll
      // if (this.userinfoAll.lawyer === null) {
      //   this.showlawyer = true
      //   store.commit('user/setLawyerFlag', false)
      // } else {
      //   store.commit('user/setLawyerFlag', true)
      //   this.showlawyer = false
      // }
      window.getLoginInfo.postMessage('{}')
    } else if (u === 'fb-flutter-Webview') {
      this.changeUSB = this.userinfoAll
      // if (this.userinfoAll.lawyer === null) {
      //   this.showlawyer = true
      //   store.commit('user/setLawyerFlag', false)
      // } else {
      //   store.commit('user/setLawyerFlag', true)
      //   this.showlawyer = false
      // }
      window.getLoginInfo.postMessage('{}')
    } else {
      // if (JSON.parse(window.sessionStorage.userInfo).lawyer === null) {
      //   this.showlawyer = true
      //   store.commit('user/setLawyerFlag', false)
      // } else {
      //   store.commit('user/setLawyerFlag', true)
      //   this.showlawyer = false
      // }
      this.changeUSB = JSON.parse(window.sessionStorage.userInfo)
    }
    this.scrollContainer = this.$el.querySelector('.liteBox')
  },
  filters: {
    reverse(str) {
      return str.replace(/-/g, '.')
    },
  },
}
</script>

<style scoped>
.comenm {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 1.5rem;
}

.liteBox {
  height: 85vh;
  overflow-y: scroll;
}

.gonna {
  text-align: center;
  line-height: 4vh;
  /* width: 70%; */
  width: 100vw;
  height: 4vh;
  position: fixed;
  bottom: 0;
  /* bottom: 10px; */
  /* left: 15%; */
  border-radius: 10px 10px 0 0px;
  background: #ffffff;
  color: #409eff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}

.borderStyle {
  color: #fe981f;
  border: 1px solid #fe981f;
  border-radius: 4px;
  height: 40px;
  margin-right: 0.2rem;
  padding: 1px 10px;
  height: 16px;
  line-height: 16px;
}

.borderStyles {
  width: 42px;
  height: 40px;
  margin-right: 20px;
  padding: 1px 10px;
  height: 16px;
  line-height: 16px;
}

.liStyle {
  font-size: 15px;
}

.titleStyle {
  font-weight: 600;
}

.collapseStyle {
  width: 30%;
  right: 0;
  top: 10px;
  position: absolute;
  z-index: 10;
  font-size: 18px;
}

.photobtn {
  position: fixed;
  width: 2rem;
  height: 2rem;
  top: 73%;
  left: 40%;
  border-radius: 50%;
  padding: 15rpx;
  box-sizing: border-box;
}

.photobtn button {
  width: 100%;
  height: 100%;
  margin: 0;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  border: none;
}

.send_btn {
  position: fixed;
  right: 28px;
  bottom: 20px;
  width: 70px;
  height: 60px;
  background: #fff;
  text-align: center;
  line-height: 60px;
  color: #fff;
  border-radius: 10%;
  font-size: 18px;
  background-color: #409eff;
}

.listheaight {
  height: 70vh;
}

.listheaighted {
  height: 85vh;
}

::v-deep .van-tab {
  font-size: 18px;
  font-weight: 600;
  color: black;
}

::v-deep .van-cell__title {
  font-size: 18px !important;
}
</style>
